import React, { useEffect } from 'react';
import { Wrapper, Window, Overlay, Close, Footer, Title, Header } from './modal.styled';
import { IconClose } from '../../app/icons/close';
import { Loading } from '../../app/loading/loading';
import { ModalAlerts } from '../alerts/modal/alerts';

type ModalType = {
    children: React.ReactNode;
    show?: boolean;
    title?: React.ReactNode | string;
    icon?: React.ReactNode;
    footer?: React.ReactNode;
    onClose?: () => void;
    loading?: boolean;
    width?: number;
    showCloseButton?: boolean;
};

export const Modal: React.FC<ModalType> = ({
    show,
    title,
    icon,
    children,
    onClose,
    footer,
    loading = false,
    width,
    showCloseButton = true,
}) => {
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [show]);

    return show ? (
        <Wrapper show={show}>
            <Window width={width}>
                <ModalAlerts />

                <Loading active={loading}>
                    <Header>
                        {icon}
                        <Title>{title}</Title>
                    </Header>
                    {children}
                    {footer && <Footer>{footer}</Footer>}
                </Loading>
                {showCloseButton && (
                    <Close onClick={onClose}>
                        <IconClose />
                    </Close>
                )}
            </Window>

            {showCloseButton ? <Overlay onClick={onClose} /> : <Overlay />}
        </Wrapper>
    ) : null;
};
