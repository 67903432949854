import React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '../modal/modal';

interface BaseDialogTitlePropsInterface {
    children: string;
    close: () => void;
}

export const BaseDialogTitle: React.FC<BaseDialogTitlePropsInterface> = observer((props) => {
    const { children, close } = props;

    return <Modal onClose={close}>{children}</Modal>;
});

export interface BaseDialogInterface {
    opened: boolean;
    close: () => void;
    icon?: React.ReactNode;
    title: React.ReactNode | string;
    content: React.ReactElement;
    action?: React.ReactElement;
    loading?: boolean;
    width?: number;
    showCloseButton?: boolean;
}

export const BaseDialog: React.FC<BaseDialogInterface> = observer(
    ({ opened, close, title, content, action, icon, loading, width, showCloseButton = true }) => {
        return (
            <Modal
                show={opened}
                title={title}
                icon={icon}
                onClose={close}
                footer={action}
                loading={loading}
                width={width}
                showCloseButton={showCloseButton}
            >
                {content}
            </Modal>
        );
    },
);
